.leaflet-control { z-index: 0 !important}
.leaflet-pane { z-index: 0 !important}
.leaflet-top, .leaflet-bottom 
{
    position: absolute;
    z-index: 1000;
    pointer-events: none;
}

.leaflet-tooltip {
  background-color: transparent;
  border: 0px;
  box-shadow: none;
  color: white;
}